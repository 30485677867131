<template>
  <div :id="createID" class="layer_pop_wrap"
       :class="{on:popOpen, fullpop: fullpopup, scrolling:scrolling, fixedBtn: fixedBtn, type}"
       @click="popBgClose"
  >
    <div class="layer_pop_cont" >
      <h2 class="pop_header" v-if="title">{{title}}</h2>
      <div class="pop_cont" ref="popcont">
        <slot></slot>
      </div>
      <a href="#" class="btn_pop_close" @click.prevent="popupClose" v-show="closeBtnSet">닫기</a>
    </div>
  </div>
</template>

<script>
export default {
  id: 'layerpopup',
  props: {
    scrolling: {
      /* iphone1.12 버그 */
      type: String,
      default: '0',
    },
    title: String,
    popOpen: Boolean,
    fullpopup: {
      type: Boolean,
      default: false,
    },
    fixedBtn: {
      type: Boolean,
      default: false,
    },
    type: String,
    closeBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    closeBtnSet() {
      const v = this.title || this.closeBtn;
      return v;
    },
    createID() {
      const r = Math.random().toString(36).substr(2, 16);
      const creatRandomID = `pop-${r}`;
      return creatRandomID;
    },
  },
  data() {
    return {
      lay: '',
      msgValidate: '',
      layerShow: true,
      scrollT: 0,
    };
  },
  methods: {
    popupClose() {
      this.$emit('popClose', false);
    },
    popBgClose(e) {
      if (e.target.classList.contains('layer_pop_wrap')) {
        this.$emit('popClose', false);
      }
    },
  },
  watch: {
    // 레이어 팝업 뜨고 끌 때 body에 class 추가 / 제거
    popOpen(val) {
      if (val) { // 추가
        this.scrollT = document.body.scrollTop ? document.body.scrollTop : document.documentElement.scrollTop;
        this.$root.popupInstance = this;
        document.body.classList.add('scrollLock');
        this.$refs.popcont.scrollTop = 0;
      } else { // 제거
        document.documentElement.scrollTop = this.scrollT;
        document.body.scrollTop = this.scrollT;
        this.$root.popupInstance = null;
        document.body.classList.remove('scrollLock');
      }
    },
  },
};
</script>

<style scoped>
</style>
